









































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Machine} from "@/models/machine";
import {namespace} from "vuex-class";
import DocumentPdfDialogComponent from "@/components/dialog/DocumentPdfDialog.component.vue";

const MachineStore = namespace('machine');

@Component({
  components: {
    EditMachineComponent: () => import('./../components/EditMachine.component.vue'),
    EditChecklistComponent: () => import('./../components/EditChecklist.component.vue'),
    ImageGalleryComponent: () => import('./dialog/InventoryImageGalleryDialog.component.vue'),
    DocumentPdfDialogComponent: () => import('./dialog/DocumentPdfDialog.component.vue')
  }
})
export default class MachineTableRowComponent extends Vue {

  @MachineStore.Action("updateMachine")
  public updateMachine!: (payload: { machine: Machine }) => Promise<Machine>

  @Prop({default: new Machine({})})
  public machine!: Machine;

  @Prop()
  public stationId!: string;

  public imageDialog = false;
  public documentPdfDialog = false;
  public checklistDialog = false;

  public snackbar: boolean = false;
  public snackbarText: string = '';

  public isSelected = false;

  @Prop({default: () => []})
  public selectedRows!: Machine[];

  @Watch('selectedRows', {immediate: true})
  public selectedRowsChanged() {
    this.isSelected = this.selectedRows.find((element: Machine) => element.id === this.machine.id) !== undefined;
  }

  public editChecklist() {
    this.checklistDialog = true;
  }

  public openImages() {
    this.imageDialog = true
  }

  public openDocumentPdf() {
    this.documentPdfDialog = true
  }




  public closeImageGalleryDialog() {
    this.imageDialog = false;
    this.documentPdfDialog = false;
  }

  public async closeChecklistDialog(edited: boolean) {
    if (edited) {
      if (this.machine) {
        await this.updateMachine({machine: this.machine});
      }
      this.snackbar = true;
      this.snackbarText = 'Checklist erfolgreich gespeichert.';
      this.$emit('closing', edited);
    }
    this.checklistDialog = false;
  }

  public selectedCheckbox() {
    if (this.isSelected) {
      const findIndex = this.selectedRows.findIndex(machine => machine.id === this.machine.id);
      this.selectedRows.splice(findIndex, 1);
      this.isSelected = false;
    } else {
      this.selectedRows.push(this.machine);
      this.isSelected = true;
    }
  }
}
